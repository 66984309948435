export default {
  id: null,
  name: null,
  avatar: {
    name: null,
    path: null,
  },
  initials: null,
  birthday: null,
  gender: null,
  comments: null,
  active: true,
}