export default {
  'ERROR': {
    id: 1,
    label: 'Erros Independentes',
    desc: 'Erros',
    color: '#F44336',
    key: 'errorsPercent',
  },
  'HIT_HELP': {
    id: 2,
    label: 'Acertos com Ajuda', 
    desc: 'Acertos com ajuda',
    color: '#54B258',
    key: 'hitsHelpPercent',
  },
  'HIT': {
    id: 3,
    label: 'Acertos Independentes', 
    desc: 'Acertos', 
    color: '#2B9BF4',
    key: 'hitsPercent',
  },
  'ERROR_HELP': {
    id: 4,
    label: 'Erros com Ajuda', 
    desc: 'Erros com ajuda', 
    color: '#FFB84F',
    key: 'errorsHelpPercent',
  },
}