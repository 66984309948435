<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer/>
            <v-list-item>
              <v-list-item-content class="text-center">
                <v-list-item-title>Relatos de Sessão</v-list-item-title>
                <v-list-item-subtitle>{{ student.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-spacer/>
            <v-avatar size="38">
              <v-img :src="student.avatar"/>
            </v-avatar>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col cols="12">
                <v-textarea outlined hide-details auto-grow label="Observações" v-model="form.comments"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="tertiary">
          <v-btn text @click="onClose">Fechar</v-btn>
          <v-spacer/>
          <v-btn depressed type="submit" color="primary">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import ReportService from '../services/report'

  const formInitial = () => ({
    id: null,
    comments: null,
  })

  export default {
    props: ['student', 'value'],
    data: () => ({
      saving: false,
      errors: {},
      form: formInitial(),
    }),
    watch: {
      value(value) {
        if (value) {
          this.mountData()
        }
      }
    },
    methods: {
      mountData() {
        this.form = formInitial()
      },
      onClose() {
        this.$emit('input', false)
      },
      onSubmit() {
        this.saving = true
        
        ReportService.create({
          student: this.student.id, ...this.form,
        })
          .then(() => {
            this.onClose()
          })
          .catch(data => {
            this.$root.$emit('snackbar', {
              text: 'Não foi possível salvar o relato da sessão.',
              color: 'red',
            })
            this.errors = data.errors || {}
          })
          .then(() => {
            this.saving = false
          })
      },
    },
  }
</script>