<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <v-list-item>
            <v-list-item-content class="text-center">
              <v-list-item-title>Gráfico</v-list-item-title>
              <v-list-item-subtitle>{{ program.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-spacer/>
          <v-avatar size="38">
            <v-img :src="student.avatar"/>
          </v-avatar>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="px-2 pb-16 mb-4">
        <v-loading v-model="loading">
          <v-container>
            <v-row align="center">
              <v-col cols="6">
                <v-dialog persistent width="290px" v-model="startDialog">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field dense outlined hide-details readonly append-icon="mdi-calendar-month-outline" label="Início" v-model="startOf" v-bind="attrs" v-on="on"/>
                  </template>
                  <v-date-picker scrollable locale="pt-br" v-model="start">
                    <v-btn text color="primary" @click="startDialog = false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startConfirm">Confirmar</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-dialog persistent width="290px" v-model="endDialog">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field dense outlined hide-details readonly append-icon="mdi-calendar-month-outline" label="Final" v-model="endOf" v-bind="attrs" v-on="on"/>
                  </template>
                  <v-date-picker scrollable locale="pt-br" v-model="end">
                    <v-btn text color="primary" @click="endDialog = false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endConfirm">Confirmar</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select hide-details outlined dense label="Exibir por estimulo" :items="stimulus" v-model="stimuluVisible"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <Chart :labels="labels" :datasets="datasets" :options="options" @started="onChartStarted"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select multiple hide-details outlined dense label="Linhas por status" :items="legends" :style="{alignItems: 'none'}" v-model="legendsVisibles">
                  <template v-slot:item="{item}">
                    <span :style="{color: legendColor(item.value)}">
                      {{ item.text }}
                    </span>
                  </template>
                  <template v-slot:selection="{item}">
                    <span :style="{marginTop: '.5rem', width: '100%', color: legendColor(item.value)}">
                      {{ item.text }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-loading>
      </v-card-text>
    </v-card>
    <v-btn dark fixed bottom right fab color="primary" @click="saveChart">
      <v-icon>mdi-content-save-outline</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
  import { chain, range, find, isNumber } from 'lodash';

  import dayjs from 'dayjs';
  import ProgramService from '../services/program';
  import Chart from '../components/Chart';
  import LegendsEnum from '../enums/LegendsEnum';
  import LegendsSelect from '../enums/LegendsSelect';
  import LegendsColor from '../enums/LegendsColor';
  import StimulusSelect from '../enums/StimulusSelect';

  export default {
    components: {Chart},
    props: ['student', 'program', 'value'],
    data: () => ({
      loading: false,
      startDialog: null,
      endDialog: null,
      legendsVisibles: [3],
      stimuluVisible: 'TODOS',
      legends: LegendsSelect,
      start: dayjs().startOf('month').format('YYYY-MM-DD'),
      end: dayjs().endOf('month').format('YYYY-MM-DD'),
      datasetsFilter: 'full',
      options: {},
      stimulus: StimulusSelect(),
      attendances: [],
      labels: [],
      datasets: [],
      chart: null,
    }),
    watch: {
      value(value) {
        if (value) {
          this.loadData()
        }
      },
      legendsVisibles() {
        this.updateChart();
      },
      stimuluVisible() {
        this.updateChart();
      }
    },
    mounted() {
      this.options.tooltip = {
        callbacks: {
          title: (tooltip) => {
            const first = tooltip[0];

            const attendance = this.attendances[first.dataIndex];

            return this.mountTooltip(attendance);
          },
          label: () => {
            return null
          },
        }
      }
    },
    methods: {
      updateChart() {
        this.mountDatasets();
        this.mountLegendsVisible();
        this.chart.update();
      },
      onChartStarted(chart) {
        this.chart = chart;
      },
      onClose() {
        this.$emit('input', false)
      },
      loadData() {
        this.loading = true;
        this.stimulus = StimulusSelect();
        this.stimuluVisible = StimulusSelect()[0];

        ProgramService.graphic({
            student: this.student.id,
            program: this.program.name,
            start: this.start,
            end: this.end,
          })
            .then((data) => {
              this.attendances = data.filter(attendance => {
                return 0 !== attendance.stimulus.group.length
              });

              if (this.attendances.length) {
                this.stimulus = [
                  ...this.stimulus,
                  ...chain(this.attendances)
                  .map(item => Object.keys(item.stimulus.group))
                  .flatten()
                  .uniq()
                  .value()
                ];
              }

              this.labels = range(1, this.attendances.length + 1);

              this.mountDatasets();
            })
            .then(() => this.loading = false)
      },
      mountDatasets() {
        this.datasets[0] = this.mountDataset(LegendsEnum.HIT, false);
        this.datasets[1] = this.mountDataset(LegendsEnum.HIT_HELP, true);
        this.datasets[2] = this.mountDataset(LegendsEnum.ERROR, true);
        this.datasets[3] = this.mountDataset(LegendsEnum.ERROR_HELP, true);
      },
      mountDataset(legend, hidden = true) {
        return {
          fill: false,
          display: false,
          hidden: hidden,
          id: legend.id,
          label: legend.label,
          borderColor: legend.color,
          backgroundColor: legend.color,
          data: 'TODOS' === this.stimuluVisible 
            ? this.attendances.map(attendance => {
              return attendance.stimulus[legend.key]
            })
            : this.attendances.map(attendance => {
              return this.calculatePercent(attendance, legend.id)
            })
        }
      },
      mountTooltip(attendance) {
        const { stimulus } = attendance;

        let total = stimulus.total;

        let percentHit = stimulus[LegendsEnum.HIT.key];
        let percentHitHelp = stimulus[LegendsEnum.HIT_HELP.key];
        let percentError = stimulus[LegendsEnum.ERROR.key];
        let percentErrorHelp = stimulus[LegendsEnum.ERROR_HELP.key];
        let infos = [];

        if ('TODOS' !== this.stimuluVisible) {
          total = stimulus.group[this.stimuluVisible] ? stimulus.group[this.stimuluVisible].length : 0;

          percentHit = this.calculatePercent(attendance, LegendsEnum.HIT.id);
          percentHitHelp = this.calculatePercent(attendance, LegendsEnum.HIT_HELP.id);
          percentError = this.calculatePercent(attendance, LegendsEnum.ERROR.id);
          percentErrorHelp = this.calculatePercent(attendance, LegendsEnum.ERROR_HELP.id);
        }

        if (total) {
          infos = [
            `${attendance.fase}`,
            `${attendance.help} ${this.showSeconds(attendance.help) ? `, ${isNumber(attendance.delay) && attendance.delay}"` : ''}`,
            
            `Tentativas: ${total}`,

            `${LegendsEnum.HIT.label}: ${percentHit}%`,
            `${LegendsEnum.HIT_HELP.label}: ${percentHitHelp}%`,
            `${LegendsEnum.ERROR.label}: ${percentError}%`,
            `${LegendsEnum.ERROR_HELP.label}: ${percentErrorHelp}%`,

            `Aplicador: ${attendance.therapist.name}`,
            `Data: ${attendance.period.end.date}`,
          ];

          if (attendance.comments) {
            infos.push('Observações', attendance.comments)
          }
        } else {
          infos = [
            `Estímulo não aplicado`,
            `neste atendimento.`
          ];
        }

        return infos.join("\n");
      },
      mountLegendsVisible() {
        this.datasets.map(dataset => dataset.hidden = true);
        
        if (this.datasets.length) {
          this.legendsVisibles.map(id => {
            find(this.datasets, {id}).hidden = false;
          });  
        }
      },
      calculatePercent(attendance, status) {
        const group = attendance.stimulus.group[this.stimuluVisible];

        if (group) {
          const total = group ? group.length : 0;
          const subtotal = group.filter(
            item => item.status === status
          ).length;
  
          return total > 0 ? Math.ceil((subtotal * 100) / total) : 0;
        }

        return 0;
      },
      saveChart() {
        const a = document.createElement('a');

        const name = this.student.name.replaceAll(' ', '_');
        const program = this.program.name.replaceAll(' ', '_');
        
        a.href = this.chart.toBase64Image();
        a.download = `${name}_${program}.png`;
        a.click();

        a.remove();
      },
      startConfirm() {
        this.startDialog = false
        this.loadData()
      },
      endConfirm() {
        this.endDialog = false
        this.loadData()
      },
      showSeconds(help) {
        return !['Ajuda necessária', 'Independente'].some(item => item.trim() === String(help).trim())
      },
      legendColor(value) {
        return LegendsColor[value];
      }
    },
    computed: {
      filterDatasets() {
        if ('full' === this.datasetsFilter) {
          return this.datasets;
        }

        return [];
      },
      startOf() {
        return this.start && dayjs(this.start).format('DD/MM/YYYY')
      },
      endOf() {
        return this.end && dayjs(this.end).format('DD/MM/YYYY')
      }
    },
  }
</script>