<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="open">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Programa</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-loading v-model="loading">
          <v-card-text>
            <v-container fluid class="px-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details label="Nome do Programa" v-model="form.name"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Local do Atendimento" :items="places" v-model="form.places"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Fase do Programa" :items="fases" v-model="form.fase"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Tipo de Ajuda" :items="helps" v-model="form.help"/>
                </v-col>
                <v-col cols="12">
                  <v-row dense align="center">
                    <v-col>
                      <v-text-field dense outlined hide-details type="number" :min="0" :max="100" label="Atraso (segundos)" v-model="form.delay"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="1" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <v-icon color="red">mdi-minus</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="2" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <v-icon color="green">mdi-plus</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="3" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <v-icon color="blue">mdi-plus-circle-outline</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox
                            hide-details
                            class="mt-0"
                            value="4"
                            v-model="form.status"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <span class="orange--text">
                            <v-icon-error/>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense align="center">
                    <v-col>
                      <v-text-field dense outlined hide-details type="number" :min="1" :max="100" label="Tentativas" v-model="form.attempts"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="auto">
                      <v-checkbox hide-details label="Randomizar" class="mt-0" v-model="form.randomize" />
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox hide-details label="Sonda" class="mt-0" v-model="form.probe" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <template v-if="form.stimulus.length">
                    <v-card outlined class="mb-2">
                      <v-simple-table>
                        <tbody>
                          <template v-for="(stimulu, index) in form.stimulus">
                            <tr :key="index">
                              <td class="text-left">
                                {{ stimulu.name }}
                              </td>
                              <td class="text-right">
                                <v-btn small icon @click="onRemoveStimulu(index)">
                                  <v-icon size="20">mdi-trash-can-outline</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                  </template>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field dense outlined hide-details label="Novo estímulo" v-model="stimulu.name" @keydown.enter.prevent="onAddStimulu">
                    <template #append>
                      <v-btn tile icon class="mt-n2 mr-n2" @click="onAddStimulu">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined hide-details label="Observações" v-model="form.comments"/>
                </v-col>
                <!-- <v-col cols="12">
                  <v-checkbox hide-details class="mt-0" label="Finalizado" v-model="form.finished"/>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tertiary">
            <v-btn text @click="onClose">Fechar</v-btn>
            <v-spacer/>
            <v-btn depressed type="submit" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-loading>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ProgramService from '../services/program';
import ProgramInitial from '../models/ProgramInitial';

const stimuluInitial = () => ({name: '', type: '1'});

export default {
  props: ['helps', 'fases'],
  data: () => ({
    saving: false,
    loading: false,
    open: false,
    stimulu: stimuluInitial(),
    errors: {},
    student: {},
    places: [
      {value: '1', text: 'Escola'}, 
      {value: '2', text: 'Casa'},
      {value: '3', text: 'Externo'},
      {value: '4', text: 'Em clínica'},
    ],
    form: ProgramInitial(),
  }),
  created() {
    this.$root.$on(
      'createProgramDialog', 
      this.onCreateProgramDialog
    )

    this.$root.$on(
      'closeCreateProgramDialog', 
      this.onCloseCreateProgramDialog
    )
  },
  methods: {
    onCreateProgramDialog(payload) {
      this.open = true;
      this.form = ProgramInitial();
      this.stimulu = stimuluInitial();
      this.student = payload.student;
    },
    onCloseCreateProgramDialog() {
      this.open = false
      this.$emit('close')
    },
    onClose() {
      this.$root.$emit('closeCreateProgramDialog')
    },
    onSubmit() {
      ProgramService.create({
        student: this.student.id,
        ...this.form,
      })
        .then(data => {
          this.$emit('save', data)
          this.onClose()
        })
        .catch(data => {
          this.$root.$emit('snackbar', {
            text: 'Não foi possível salvar os dados.',
            color: 'red',
          })
          this.errors = data.errors || {}
        })
        .then(() => {
          this.saving = false
        })
    },
    onAddStimulu() {
      if (this.notHasStimulu) {
        this.form.stimulus.push(this.stimulu);
        this.stimulu = stimuluInitial();
      } else {
        alert('Estimulo inválido!');
      }
    },
    onRemoveStimulu(index) {
      this.form.stimulus.splice(index, 1)
    },
  },
  computed: {
    notHasStimulu() {
      return this.stimulu && this.stimulu.name && !this.form.stimulus.some(stimulu => {
        return stimulu.name == this.stimulu.name;
      });
    },
  }
}
</script>