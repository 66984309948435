import http from './http'

const create = (data) => {
  return http.post('supervisions/create', data)
}

const update = (data) => {
  return http.post('supervisions/update', data)
}

const trash = (data) => {
  return http.post('supervisions/delete', data)
}

export default { 
  create,
  update,
  trash,
}