import http from './http'

const data = (id) => {
  return http.get('programs/data', {
    params: {
      id: id,
    }
  })
}

const find = (id) => {
  return http.get('programs/find', {
    params: {id}
  })
}

const options = () => {
  return http.get('programs/options')
}

const lasts = () => {
  return http.get('programs/lasts')
}

const create = (data) => {
  return http.post('programs/create', data)
}

const update = (data) => {
  return http.post('programs/update', data)
}

const trash = (data) => {
  return http.post('programs/trash', data)
}

const finish = (id) => {
  return http.post('programs/finish', {
    id: id
  })
}

const graphic = (params) => {
  return http.get('programs/graphic', {
    params
  })
}

const supervisions = (id) => {
  return http.get(`programs/supervisions/${id}`)
}

const maintenance = (data) => {
  return http.post('programs/maintenance', data)
}

export default { 
  data, 
  lasts, 
  create,
  update,
  trash,
  finish,
  graphic,
  maintenance,
  find,
  options,
  supervisions,
}