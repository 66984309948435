<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer/>
            <v-list-item>
              <v-list-item-content class="text-center">
                <v-list-item-title>Relatos de Sessão</v-list-item-title>
                <v-list-item-subtitle>{{ student.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-spacer/>
            <v-avatar size="38">
              <v-img :src="student.avatar"/>
            </v-avatar>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row>
              <v-col cols="12">
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ reportData.profile && reportData.profile.name  }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ reportData.profile && reportData.profile.profession }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ onlyDate(reportData.date) }}
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <template v-if="isOwner">
                  <v-textarea outlined hide-details auto-grow label="Observações" v-model="reportData.comments"/>
                </template>
                <template v-else>
                  {{ reportData.comments }}
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="tertiary">
          <v-btn text @click="onClose">Fechar</v-btn>
          <v-spacer/>
          <template v-if="isOwner">
            <v-btn depressed :disabled="saving" :loading="saving" type="button" color="primary" @click="onSave">
              Salvar
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import ReportService from '../services/report'

  import { formater } from '../services/dayjs'

  export default {
    props: ['student', 'report', 'value'],
    data: () => ({
      saving: false,
      reportData: {
        profile: {},
      },
    }),
    watch: {
      value(value) {
        if (value) {
          this.reportData = {...this.report}
        }
      }
    },
    methods: {
      onClose() {
        this.$emit('input', false)
      },
      onSave() {
        this.saving = true

        ReportService.update(this.reportData)
          .then(() => {
            this.report.comments = this.reportData.comments
            this.$emit('input', false)
          })
          .catch(() => this.$emit('input', false))
          .then(() => this.saving = false)
      },
      onlyDate(date) {
        return formater(date)
      }
    },
    computed: {
      isOwner() {
        return !!this.report.profile && (this.report.profile.code === this.$store.getters.profile.code)
      },
    }
  }
</script>