import LegendsEnum from './LegendsEnum';

export default [
  {
    text: LegendsEnum.HIT.label, 
    value: LegendsEnum.HIT.id
  },
  {
    text: LegendsEnum.HIT_HELP.label, 
    value: LegendsEnum.HIT_HELP.id
  },
  {
    text: LegendsEnum.ERROR.label, 
    value: LegendsEnum.ERROR.id
  },
  {
    text: LegendsEnum.ERROR_HELP.label, 
    value: LegendsEnum.ERROR_HELP.id
  },
];