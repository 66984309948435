<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Arquivo</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-loading v-model="loading">
          <v-card-text>
            <v-container fluid class="px-0">
              <v-row>
                <v-col cols="12">
                  <FileUploader @choose="onChoose" @success="onUploaded">
                    Selecionar Arquivo
                  </FileUploader>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined hide-details label="Descrição" v-model="form.description"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tertiary">
            <v-btn text @click="onClose">Fechar</v-btn>
            <v-spacer/>
            <v-btn depressed type="submit" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-loading>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import FileUploader from '../components/FileUploader'
  import FileService from '../services/file'

  const formInitial = () => ({
    name: null,
    path: null,
    description: null,
  })

  export default {
    props: ['student', 'value'],
    components: {
      FileUploader
    },
    data: () => ({
      saving: false,
      loading: false,
      errors: {},
      form: formInitial(),
    }),
    watch: {
      value(value) {
        if(value) {
          this.onOpen()
        }
      }
    },
    methods: {
      onOpen() {
        this.form = formInitial()
        this.errors = {}
        this.saving = false
        this.loading = false
      },
      onClose() {
        this.$emit('input', false)
      },
      onSubmit() {
        FileService.create({
          student: this.student.id,
          ...this.form,
        })
          .then(data => {
            this.$emit('save', data)
            this.onClose()
          })
          .catch(data => {
            this.$root.$emit('snackbar', {
              text: 'Não foi possível salvar os dados.',
              color: 'red',
            })
            this.errors = data.errors || {}
          })
          .then(() => {
            this.saving = false
          })
      },
      onUploaded(file) {
        this.form.path = file.path
      },
      onChoose(file) {
        this.form.name = file.name
      }
    },
  }
</script>