<template>
  <v-loading v-model="loading">
    <portal to="app-bar">
      <v-app-bar app short dark extended color="primary" elevation="0" height="56">
        <v-app-bar-nav-icon @click="$root.$emit('drawer')"/>
        <v-spacer/>
        <v-toolbar-title>
          {{ student.name }}
        </v-toolbar-title>
        <v-spacer/>
        <Avatar :avatar="student.avatar.path" :initials="student.initials"/>
        <template #extension>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="8">
                <v-menu offset-y nudge-bottom="10">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn depressed block tile color="primary" v-bind="attrs" v-on="on">
                      {{ label }} <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="choiseTab(0, 'Programas')">
                      <v-list-item-title>Programas</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="choiseTab(1, 'Atendimentos')">
                      <v-list-item-title>Atendimentos</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="choiseTab(2, 'Relatos')">
                      <v-list-item-title>Relatos</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="choiseTab(3, 'Arquivos')">
                      <v-list-item-title>Arquivos</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-app-bar>
    </portal>

    <v-tabs-items v-model="tabs">
      <!-- programas -->
      <v-tab-item class="pb-10 mb-12">
        <v-list class="v-list-default">
          <template v-for="program in programsFiltered">
            <v-list-item :key="program.id">
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold">
                  {{ program.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ program.faseName }}, {{ program.helpName }} {{ showSeconds(program.helpName) ? `, ${program.delay}"` : '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu bottom>
                  <template v-slot:activator="{on}">
                    <v-btn small icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-if="['therapist', 'supervisor', 'coordinator'].includes(user.role.name)">
                      <v-list-item @click="onEditProgram(program)">
                        <v-list-item-icon class="mr-2">
                          <v-icon size="20">mdi-square-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-if="['therapist', 'supervisor', 'coordinator'].includes(user.role.name)">
                      <v-list-item @click="onFinishedProgram(program)">
                        <v-list-item-icon class="mr-2">
                          <v-icon size="20">
                            {{ program.finished ? 'mdi-clipboard-check-outline' : 'mdi-clipboard-check-outline' }} 
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ program.finished ? 'Reativar' : 'Finalizar' }} 
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-if="['supervisor', 'coordinator'].includes(user.role.name)">
                      <v-list-item @click="onTrashProgram(program)">
                        <v-list-item-icon class="mr-2">
                          <v-icon size="20">mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list-item @click="onSupervisionsDialog(program)">
                      <v-list-item-icon class="mr-2">
                        <v-icon size="20">
                          mdi-eye-check-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Supervisões</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onGraphicProgram(program)">
                      <v-list-item-icon class="mr-2" >
                        <v-icon size="20">mdi-chart-line</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Gráficos</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-tab-item>
      
      <!-- atendimetos -->
      <v-tab-item class="pb-10 mb-12">
        <template v-if="paused">
          <v-list class="v-list-default">
            <v-list-item class="yellow lighten-4">
              <v-list-item-content>
                <v-list-item-title class="text-uppercase font-weight-bold">
                  {{ pausedProgramsTotalCompleted }} de {{ pausedProgramsTotal }} programas completos
                </v-list-item-title>
                <v-list-item-subtitle>
                  Iniciado: {{ pausedStartDate }} às {{ pausedStartHour }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon :to="{name: 'attendance', query: {id: paused.student.id}}">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <v-list class="v-list-default">
          <template v-for="(items, date) in attendances">
            <v-subheader :key="date">
              <span class="subtitle-2">{{ formatDate(date) }}</span>
            </v-subheader>
            <template v-for="attendance in items">
              <v-list-item :key="attendance.id">
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase font-weight-bold">
                    {{ onlyHour(attendance.start) }} às {{ onlyHour(attendance.end) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ attendance.therapist.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row dense align="center">
                    <v-col cols="auto">
                      <small>{{ onlyMinutes(attendance.start, attendance.end) }} min</small>
                    </v-col>
                    <v-col cols="auto">
                      <v-menu bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small icon v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="onViewAttendance(attendance)">
                            <v-list-item-icon class="mr-2">
                              <v-icon size="20">mdi-eye-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Visualizar</v-list-item-title>
                          </v-list-item>
                          <template v-if="['supervisor', 'coordinator'].includes(user.role.name)">
                            <v-list-item @click="onTrashAttendance(attendance)">
                              <v-list-item-icon class="mr-2">
                                <v-icon size="20">mdi-trash-can-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Apagar</v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-tab-item>

      <!-- relatos -->
      <v-tab-item class="pb-10 mb-12">
        <v-list subheader class="v-list-default">
          <template v-for="(items, date) in reports">
            <v-subheader :key="date">
              <span class="subtitle-2">{{ formatDate(date) }}</span>
            </v-subheader>
            <template v-for="report in items">
              <v-list-item :key="report.id">
                <v-list-item-content @click="onViewReport(report)">
                  <v-list-item-subtitle>
                    {{ report.profile.name }}
                  </v-list-item-subtitle>
                  <v-list-item-title style="white-space: nowrap !important;">
                    {{ report.comments }}
                  </v-list-item-title>
                  <template v-if="report.profile.profession">
                    <v-list-item-subtitle>
                      {{ report.profile.profession }}
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="onTrashReport(report)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-tab-item>

      <!-- arquivos -->
      <v-tab-item class="pb-10 mb-12">
        <v-list class="v-list-default">
          <template v-for="file in files">
            <v-list-item :key="file.id">
              <v-list-item-content @click="downloaFile(file)">
                <v-list-item-title style="white-space: nowrap !important;">
                  {{ file.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ file.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="onTrashFile(file)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-tab-item>
    </v-tabs-items>

    <v-fab-transition>
      <v-speed-dial fixed bottom right v-show="isTab(0)" transition="slide-y-reverse-transition" class="v-speed-dial-default">
        <template v-slot:activator>
          <v-badge left :content="programsSpeedDialBadgeContent" offset-y="42" offset-x="-8" :value="true">
            <v-btn fab color="primary">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <template v-if="['therapist', 'supervisor', 'coordinator'].includes(user.role.name)">
          <v-badge left content="Adicionar" offset-y="38" offset-x="-2" :value="true">
            <v-btn fab small color="primary" @click="onCreateProgram">
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-badge left content="Finalizados" offset-y="38" offset-x="-2" :value="true">
          <v-btn fab small color="primary" @click="programsFilter = 'finished'">
            <v-icon size="20">mdi-check-all</v-icon>
          </v-btn>
        </v-badge>
        <v-badge left content="Manutenções" offset-y="38" offset-x="-2" :value="true">
          <v-btn fab small color="primary" @click="programsFilter = 'maintenance'">
            <v-icon size="18">mdi-wrench-outline</v-icon>
          </v-btn>
        </v-badge>
        <v-badge left content="Programas em Clínicas" offset-y="38" offset-x="-2" :value="true">
          <v-btn fab small color="primary" @click="programsFilter = 'clinical'">
            <v-icon size="18">mdi-hospital-building</v-icon>
          </v-btn>
        </v-badge>
        <v-badge left content="Programas Externos" offset-y="38" offset-x="-2" :value="true">
          <v-btn fab small color="primary" @click="programsFilter = 'external'">
            <v-icon size="18">mdi-map-marker-outline</v-icon>
          </v-btn>
        </v-badge>
        <v-badge left content="Programas de Escola" offset-y="38" offset-x="-2" :value="true">
          <v-btn fab small color="primary" @click="programsFilter = 'others'">
            <v-icon size="18">mdi-filter-outline</v-icon>
          </v-btn>
        </v-badge>
        <v-badge left content="Programas de Casa" offset-y="38" offset-x="-2" :value="true">
          <v-btn fab small color="primary" @click="programsFilter = 'house'">
            <v-icon size="20">mdi-home-outline</v-icon>
          </v-btn>
        </v-badge>
      </v-speed-dial>
    </v-fab-transition>

    <template v-if="['therapist'].includes(user.role.name)">
      <v-fab-transition>
        <v-btn fab fixed bottom right color="primary" v-show="isTab(1)" @click="onStartAttendance">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-fab-transition>
      <v-btn fab fixed bottom right color="primary" v-show="isTab(2)" @click="onCreateReport">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn fab fixed bottom right color="primary" v-show="isTab(3)" @click="onCreateFile">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <CreateReportDialog 
      :student="student"
      v-model="createReportDialog"
    />

    <ViewReportDialog 
      :student="student"
      :report="report"
      v-model="viewReportDialog"
    />

    <ViewAttendanceDialog 
      :attendance="attendance"
      v-model="viewAttendanceDialog"
    />

    <CreateProgramDialog 
      :fases="fases" 
      :helps="helps" 
      @close="onFetchAll"
    />
    
    <EditProgramDialog
      :fases="fases" 
      :helps="helps" 
      :student="student"
      :program="program"
      v-model="editProgramDialog"
    />

    <CreateFileDialog
      :student="student"
      v-model="createFileDialog"
      @save="onUploaded"
    />

    <!-- <ChoiseProgramsDialog
      :student="student"  
      :programs="programs"
      v-model="choiseProgramsDialod"
     /> -->

     <GraphicsDialog
      :student="student"
      :program="program"
      v-model="graphicProgramDialod"
     />

     <SupervisionsDialog
      :student="student"
      :program="program"
      v-model="supervisionsDialog"
     />
  </v-loading>
</template>

<script>
  import Avatar from '../../components/Avatar'
  import { onlyDateBR, onlyHourBR, difference, formater } from '../../services/dayjs'
  import FileService from '../../services/file'
  import StudentService from '../../services/student'
  import ConfigService from '../../services/config'
  import AttendanceService from '../../services/attendance'
  import ProgramService from '../../services/program'
  import ReportService from '../../services/report'
  import StudentModel from '../../models/Student'
  import CreateReportDialog from '../../dialogs/CreateReportDialog'
  import ViewReportDialog from '../../dialogs/ViewReportDialog'
  import ViewAttendanceDialog from '../../dialogs/ViewAttendanceDialog'
  import CreateProgramDialog from '../../dialogs/CreateProgramDialog'
  import CreateFileDialog from '../../dialogs/CreateFileDialog'
  import EditProgramDialog from '../../dialogs/EditProgramDialog'
  import GraphicsDialog from '../../dialogs/GraphicsDialog'
  import SupervisionsDialog from '../../dialogs/SupervisionsDialog'
  // import ChoiseProgramsDialog from '../../dialogs/ChoiseProgramsDialog'

  export default {
    components: {
      Avatar,
      CreateReportDialog,
      ViewReportDialog,
      ViewAttendanceDialog,
      CreateProgramDialog,
      CreateFileDialog,
      EditProgramDialog,
      GraphicsDialog,
      // ChoiseProgramsDialog,
      SupervisionsDialog,
    },
    data: () => ({
      programsFilter: 'house',
      createReportDialog: false,
      createFileDialog: false,
      viewReportDialog: false,
      viewAttendanceDialog: false,
      editProgramDialog: false,
      attendanceDialog: false,
      // choiseProgramsDialod: false,
      graphicProgramDialod: false,
      supervisionsDialog: false,
      loading: false,
      create: false,
      selecting: false,
      tabs: 0,
      paused: null,
      selecteds: [],
      programs: [],
      attendances: [],
      reports: [],
      files: [],
      helps: [],
      fases: [],
      program: {},
      report: {},
      attendance: {},
      label: 'Programas',
      student: StudentModel,
    }),
    mounted() {
      this.onFetchAll()
    },
    watch: {
      editProgramDialog(value) {
        if (!value) {
          this.onFetchAll()
        }
      },
      createReportDialog(value) {
        if (!value) {
          this.onFetchAll()
        }
      }
    },
    methods: {
      choiseTab(tab, label) {
        this.tabs = tab
        this.label = label
      },
      showSeconds(help) {
        return !['Ajuda necessária', 'Independente'].some(item => item.trim() === String(help).trim())
      },
      onFetchAll() {
        this.loading = true

        Promise.all([
          this.fetchStudent(),
          this.fetchPrograms(),
          this.fetchAttendances(),
          this.fetchReports(),
          this.fetchFiles(),
          this.fetchFases(),
          this.fetchHelps(),
        ]).then(() => {
          this.loading = false
          this.fetchAttendancePaused()
        })
      },
      onCreateReport() {
        this.createReportDialog = true
      },
      onCreateFile() {
        this.createFileDialog = true
      },
      onViewReport(report) {
        this.report = report
        this.viewReportDialog = true
      },
      onViewAttendance(attendance) {
        this.attendance = attendance
        this.viewAttendanceDialog = true
      },
      onCreateProgram() {
        this.$root.$emit('createProgramDialog', {
          student: this.student
        })
      },
      onEditProgram(program) {
        this.program = program
        this.editProgramDialog = true
      },
      onTrashProgram(program) {
        if (confirm('Deseja realmente apagar este programa?')) {
          ProgramService.trash({id: program.id})
            .then(() => {
              this.onFetchAll();
            })
            .catch(() => {
              alert('Não foi possível apagar o programa!');
            });
        }
      },
      onTrashAttendance(attendance) {
        if (confirm('Deseja realmente apagar este atendimento?')) {
          AttendanceService.trash({id: attendance.id})
            .then(() => {
              this.onFetchAll();
            })
            .catch(() => {
              alert('Não foi possível apagar o atendimento!');
            });
        }
      },
      onTrashReport(report) {
        if (confirm('Deseja realmente apagar este relato?')) {
          ReportService.trash({
            reportId: report.id,
            studentId: this.student.id,
          })
            .then(() => {
              this.onFetchAll();
            })
            .catch(() => {
              alert('Não foi possível apagar o relato!');
            });
        }
      },
      onTrashFile(file) {
        if (confirm('Deseja realmente apagar este arquivo?')) {
          FileService.trash({
            id: file.id,
          })
            .then(() => {
              this.onFetchAll();
            })
            .catch(() => {
              alert('Não foi possível apagar o arquivo!');
            });
        }
      },
      onFinishedProgram(program) {
        ProgramService.finish(program.id)
          .then(() => {
            this.onFetchAll()
          })
      },
      onGraphicProgram(program) {
        this.program = program
        this.graphicProgramDialod = true
      },
      onSupervisionsDialog(program) {
        this.program = program
        this.supervisionsDialog = true
      },
      onStartAttendance() {
        AttendanceService.started(this.student)
          .then(exists => {
            if (!exists) {
              this.$router.push({
                name: 'attendance.programs',
                query: { id: this.student.id },
                params: {
                  student: this.student,
                  programs: this.programs,
                },
              });
              // this.choiseProgramsDialod = true
            } else {
              this.$root.$emit('snackbar', {
                text: 'Você deve finalizar o atendimento anterior antes de iniciar um novo.',
                color: 'red',
              })
            }
          })
      },
      onUploaded(file) {
        this.files.push(file)
      },
      downloaFile(file) {
        FileService.download(file)
      },
      fetchStudent() {
        return StudentService.find(this.$route.query.id)
          .then(data => {
            this.student = data
          })
      },
      fetchPrograms() {
        return StudentService.programs(this.$route.query.id)
          .then(data => {
            this.programs = data
          })
      },
      fetchAttendances() {
        return StudentService.attendances(this.$route.query.id)
          .then(data => {
            this.attendances = data
          })
      },
      fetchReports() {
        return StudentService.reports(this.$route.query.id)
          .then(data => {
            this.reports = data
          })
      },
      fetchFiles() {
        return StudentService.files(this.$route.query.id)
          .then(data => {
            this.files = data
          })
      },
      fetchFases() {
        return ConfigService.fases()
          .then(data => {
            this.fases = data
          })
      },
      fetchHelps() {
        return ConfigService.helps()
          .then(data => {
            this.helps = [
              ...data
            ]
          })
      },
      fetchAttendancePaused() {
        return AttendanceService.paused(this.student.id)
          .then(data => {
            this.paused = data
          })
      },
      isTab(tab) {
        return this.tabs === tab
      },
      formatDate(date) {
        return formater(date)
      },
      onlyDate(date) {
        return onlyDateBR(date)
      },
      onlyHour(date) {
        return onlyHourBR(date)
      },
      onlyMinutes(start, end) {
        return difference(start, end)
      }
    },
    computed: {
      pausedProgramsTotal() {
        return this.paused && this.paused.programs.length
      },
      pausedProgramsTotalCompleted() {
        return this.paused && this.paused.programs.filter(program => {
          return program.applications.every(application => application.status !== null)
        }).length
      },
      pausedStartDate() {
        return this.paused && this.onlyDate(this.paused.start)
      },
      pausedStartHour() {
        return this.paused && this.onlyHour(this.paused.start)
      },
      user() {
        return this.me().user
      },
      programsFiltered() {
        return this.programs.filter(item => {
          if (this.programsFilter === 'house') {
            return item.fase !== 1 && item.places === '2' && (item.finished === false)
          }

          if (this.programsFilter === 'external') {
            return item.fase !== 1 && item.places === '3' && (item.finished === false)
          }

          if (this.programsFilter === 'clinical') {
            return item.fase !== 1 && item.places === '4' && (item.finished === false)
          }

          if (this.programsFilter === 'others') {
            return item.fase !== 1 && item.places === '1' && (item.finished === false)
          }

          if (this.programsFilter === 'maintenance') {
            return item.fase === 1 && (item.finished === false)
          }

          if (this.programsFilter === 'finished') {
            return item.finished
          }
        })
      },
      programsSpeedDialBadgeContent() {
        return {
          others: 'Programas de Escola',
          maintenance: 'Manutenções',
          finished: 'Finalizados',
          house: 'Programas de Casa',
          external: 'Programas Externos',
          clinical: 'Programas em Clínicas',
        }[this.programsFilter]
      }
    }
  }
</script>

<style lang="scss">
  .v-speed-dial-student {
    .v-badge__badge {
      font-size: inherit;
      height: 25px;
      padding: 4px 10px;
      border-radius: 30px;
    }
  }

  .v-tabs-bar {
    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }
  }

  .v-speed-dial-default {
    .v-badge__badge {
      font-size: inherit;
      height: 27px;
      padding: 6px 11px;
      border-radius: 30px;
    }
  }
</style>