import http from './http'

const all = () => {
  return http.get('configurations/all')
}

const helps = () => {
  return http.get('configurations/helps')
}

const fases = () => {
  return http.get('configurations/fases')
}

export default { 
  all, 
  helps, 
  fases,
}