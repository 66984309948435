export default {
  id: null,
  name: null,
  attempts: null,
  comments: null,
  status: ['1', '2', '3'],
  fase: null,
  help: null,
  delay: 0,
  finished: false,
  stimulus: [],
}