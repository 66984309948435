<template>
  <canvas ref="chart" :width="width" :height="height"></canvas>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

export default {
  props: {
    width: {
      default: '100%'
    },
    height: {
      default: '100%'
    },
    labels: Array,
    datasets: {
      type: Array,
      required: true
    },
    options: Object
  },
  mounted() {

    Chart.register(zoomPlugin);
    Chart.register(...registerables);

    Chart.defaults.elements.point.radius = 5;
    
    Chart.defaults.elements.line.tension = 0;

    Chart.defaults.plugins.legend.display = false;
    Chart.defaults.plugins.legend.position = 'bottom';
    Chart.defaults.plugins.legend.align = 'start';
    Chart.defaults.plugins.legend.labels.boxWidth = 20;

    const chart = new Chart(this.$refs.chart, {
      type: 'line',
      scaleOverride: true,
      scaleSteps: 10,
      scaleStepWidth: 10,
      scaleStartValue: 0,
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        responsive: true,
        scales: {
          y: {
            suggestedMin: 0,
            suggestedMax: 100,
            stepSize: 10,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'top',
          },
          zoom: {
            mode: 'xy',
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            wheel: {
              enabled: true,
            },
          },
          tooltip: this.options.tooltip
        },
      }
    });

    this.$emit('started', chart);
  }
}
</script>