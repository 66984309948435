<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-list-item>
            <v-list-item-content class="text-center">
              <v-list-item-title>Supervisões</v-list-item-title>
              <v-list-item-subtitle>{{ program.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-loading v-model="loading">
          <template v-for="(list, date) in items">
            <h3 class="pa-3 pb-0" :key="date">{{ date }}</h3>
            <v-container :key="`g-${date}`">
              <template v-for="supervision in list">
                <v-row :key="supervision.id">
                  <v-col cols="12">
                    <template v-if="['supervisor', 'coordinator'].includes(user.role.name)">
                      <v-textarea auto-grow dense outlined hide-details background-color="white" row-height="24" rows="1" placeholder="Nova supervisão" v-model="supervision.comments">
                        <template #append-outer>
                          <v-btn icon depressed :loading="supervision.editing" @click="onUpdateSupervision(supervision)">
                            <v-icon>mdi-content-save-outline</v-icon>
                          </v-btn>
                          <v-btn icon depressed :loading="supervision.editing" @click="onTrashSupervision(supervision)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                      </v-textarea>
                    </template>
                    <template v-else>
                      {{ supervision.comments }}
                    </template>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </template>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn small fab depressed color="primary" class="mr-3" :disabled="isFirstPage" @click="pagePreview">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn small fab depressed color="primary" :disabled="isLastPage" @click="pageNext">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-loading>
      </v-card-text>
      <template v-if="['supervisor', 'coordinator'].includes(user.role.name)">
        <v-card-actions class="tertiary">
          <v-textarea auto-grow dense outlined hide-details background-color="white" row-height="24" rows="1" placeholder="Nova supervisão" append-outer-icon="mdi-send" v-model="form.comments" @click:append-outer="addSupervision" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { groupBy, chunk } from 'lodash';
import { formater } from '../services/dayjs';
import StudentModel from '../models/Student';
import ProgramModel from '../models/Program';
import ProgramService from '../services/program';
import SupervisionService from '../services/supervision';

export default {
  props: {
    value: Boolean,
    student: { default: StudentModel },
    program: { default: ProgramModel },
  },
  data: () => ({
    loading: false,
    saving: false,
    supervisions: [],
    page: 0,
    count: 10,
    form: {
      comments: '',
    },
  }),
  watch: {
    value(value) {
      if (value) {
        this.fetchSupervisions();
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    fetchSupervisions() {
      this.loading = true;

      ProgramService.supervisions(this.program.id)
        .then((data) => {
          this.supervisions = data.map((item) => ({
            editing: false,
            ...item,
          }));
        })
        .catch(() => {})
        .then(() => (this.loading = false));
    },
    addSupervision() {
      this.saving = true;

      SupervisionService.create({
        program: this.program.id,
        ...this.form,
      })
        .then((data) => {
          this.supervisions.unshift(data);
          this.form = {
            comments: '',
          };
        })
        .catch(() => {})
        .then(() => (this.saving = false));
    },
    onUpdateSupervision(supervision) {
      supervision.editing = true;

      SupervisionService.update(supervision)
        .then(() => {})
        .catch(() => {})
        .then(() => (supervision.editing = false));
    },
    onTrashSupervision(supervision) {
      if (confirm('Deseja realmente apagar esta supervisão?')) {
          SupervisionService.trash({
            id: supervision.id,
          })
            .then(() => {
              this.supervisions = this.supervisions.filter(item => {
                return item.id != supervision.id
              });
            })
            .catch(() => {
              alert('Não foi possível apagar a supervisão!');
            });
        }
    },
    format(date) {
      return formater(date);
    },
    pagePreview() {
      this.page = Math.max(this.page - 1, 0);
    },
    pageNext() {
      this.page = Math.min(this.page + 1, Math.ceil(this.pages.length) - 1);
    },
  },
  computed: {
    isFirstPage() {
      return 0 === this.page;
    },
    isLastPage() {
      return this.page === this.pages.length - 1;
    },
    pages() {
      return chunk(this.supervisions, this.count);
    },
    paginated() {
      return this.pages[this.page];
    },
    items() {
      return groupBy(this.paginated, (item) => {
        return this.format(item.created_at);
      });
    },
    user() {
      return this.me().user;
    },
  },
};
</script>
