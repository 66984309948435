<template>
  <v-file-input outlined dense hide-details label="Selecionar arquivo" :disabled="saving" :loading="saving" @change="onChoose"/>
</template>

<script>
  import UploadService from '../services/upload'

  export default {
    data: () => ({
      saving: false
    }),
    methods: {
      onChoose(file) {
        if (file) {
          this.saving = true

          this.$emit('choose', file)
  
          UploadService.file(file)
            .then(data => {
              this.$emit('success', data)
            })
            .catch(() => {})
            .then(() => this.saving = false)
        }
      }
    }
}
</script>