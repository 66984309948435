import FileDownload from 'js-file-download'

import http from './http'

const create = (data) => {
  return http.post('files/create', data)
}

const download = (file) => {
  return http.get(file.path, {
    responseType: 'blob'
  }).then(response => {
    FileDownload(response, file.name)
  })
}

const trash = (data) => {
  return http.post('files/delete', data)
}

export default {
  create,
  download,
  trash,
}