export default (props = {}) => ({
  name: null,
  comments: null,
  status: ['1', '2', '3', '4'],
  fase: null,
  help: null,
  delay: 0,
  attempts: 1,
  finished: false,
  stimulus: [],
  places: '1',
  randomize: true,
  probe: false,
  ...props,
})