<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <v-list-item>
            <v-list-item-content class="text-center">
              <v-list-item-title>Atendimento</v-list-item-title>
              <v-list-item-subtitle>{{ info.student }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-spacer/>
        </v-toolbar>
      </v-card-title>
      <v-loading v-model="loading">
        <v-card-text>
          <v-simple-table dense class="my-3">
            <tr>
              <th class="text-left">Data</th>
              <td>{{ info.date }}</td>
            </tr>
            <tr>
              <th class="text-left">Aplicador</th>
              <td>{{ info.therapist }}</td>
            </tr>
          </v-simple-table>
          <h3 class="py-3">Programas</h3>
          <template v-for="(program, index) in info.programs">
            <v-card class="mb-3" elevation="2" :key="index" outlined>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td class="text-left">
                      <strong>Nome</strong>
                    </td>
                    <td class="text-right">
                      {{ program.name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      <strong>Fase</strong>
                    </td>
                    <td class="text-right">
                      {{ program.fase }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      <strong>Ajuda</strong>
                    </td>
                    <td class="text-right">
                      {{ program.help }} {{ showSeconds(program.help) ? `, ${program.delay}"` : '' }}
                    </td>
                  </tr>

                  <template v-if="program.probes">
                    <tr class="grey lighten-3">
                      <td colspan="2" class="text-left">
                        <strong>Sonda</strong>
                      </td>
                    </tr>
                    <template v-for="(stimulus, name) in program.probes">
                      <tr :key="`probe-${name}`" class="yellow lighten-5">
                        <td class="text-left">
                          <strong>{{ name }}</strong>
                        </td>
                        <td class="text-right">
                          <div class="justify-end d-flex align-center">
                            <template v-for="(status, code) in stimulus">
                              <template v-if="code === `${name}1`">
                                <v-icon :key="`icon-probe-${code}`" color="red" class="ml-3">mdi-minus</v-icon>
                              </template>
                              <template v-if="code === `${name}2`">
                                <v-icon :key="`icon-probe-${code}`" color="green" class="ml-3">mdi-plus</v-icon>
                              </template>
                              <template v-if="code === `${name}3`">
                                <v-icon :key="`icon-probe-${code}`" color="blue" class="ml-3">mdi-plus-circle-outline</v-icon>
                              </template>
                              <template v-if="code === `${name}4`">
                                <span :key="`icon-probe-${code}`" class="ml-3 orange--text d-inline-flex">
                                  <v-icon-error/>
                                </span>
                              </template>
                              <template v-if="code">
                                <span class="ml-1" :key="`total-probe-${code}`">{{ status.length }}</span>
                              </template>
                            </template>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                  
                  <template v-if="program.normals">
                    <tr class="grey lighten-3">
                      <td colspan="2" class="text-left">
                        <strong>Aplicações</strong>
                      </td>
                    </tr>
                    <template v-for="(stimulus, name) in program.normals">
                      <tr :key="`normal-${name}`">
                        <td class="text-left">
                          <strong>{{ name }}</strong>
                        </td>
                        <td class="text-right">
                          <div class="justify-end d-flex align-center">
                            <template v-for="(status, code) in stimulus">
                              <template v-if="parseInt(code, 10) === 1">
                                <v-icon :key="`icon-normal-${code}`" color="red" class="ml-3">mdi-minus</v-icon>
                              </template>
                              <template v-if="parseInt(code, 10) === 2">
                                <v-icon :key="`icon-normal-${code}`" color="green" class="ml-3">mdi-plus</v-icon>
                              </template>
                              <template v-if="parseInt(code, 10) === 3">
                                <v-icon :key="`icon-normal-${code}`" color="blue" class="ml-3">mdi-plus-circle-outline</v-icon>
                              </template>
                              <template v-if="parseInt(code, 10) === 4">
                                <span :key="`icon-normal-${code}`" class="ml-3 orange--text d-inline-flex">
                                  <v-icon-error/>
                                </span>
                              </template>
                              <template v-if="code">
                                <span class="ml-1" :key="`total-normal-${code}`">{{ status.length }}</span>
                              </template>
                            </template>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>
        </v-card-text>
        <v-card-actions class="tertiary">
          <v-btn text @click="onClose">Fechar</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-loading>
    </v-card>
  </v-dialog>
</template>

<script>
  import { groupBy } from 'lodash'
  import { formater } from '../services/dayjs'
  import AttendanceService from '../services/attendance'

  const initialInfo = {
    date: null,
    student: null,
    therapist: null,
    programs: [],
  }

  export default {
    props: ['attendance', 'value'],
    data: () => ({
      loading: false,
      info: initialInfo
    }),
    watch: {
      value(value) {
        if (value) {
          this.fetch()
        } else {
          this.info = initialInfo
        }
      }
    },
    methods: {
      fetch() {
        this.loading = true
        
        AttendanceService.view(this.attendance.id)
          .then(data => {
            this.info = data
          })
          .catch(() => this.onClose())
          .then(() => this.loading = false)
      },
      onClose() {
        this.$emit('input', false)
      },
      onlyDate(date) {
        return formater(date)
      },
      parseStimulus(stimulus) {
        return groupBy(stimulus, item => {
          return item.name
        })
      },
      parseStimulusStatus(stimulus) {
        return groupBy(stimulus.filter(item => item.status !== null), item => {
          return item.status
        })
      },
      showSeconds(help) {
        return !['Ajuda necessária', 'Independente'].some(item => item.trim() === String(help).trim())
      }
    },
    computed: {
      attendancesFilter() {
        return this.info.applications.filter(application => {
          return application.stimulus.some(stimulu => null !== stimulu.status)
        })
      }
    }
  }
</script>