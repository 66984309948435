<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Programa</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-loading v-model="loading">
          <v-card-text>
            <v-container fluid class="px-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details :disabled="!['supervisor'].includes(user.role.name)" :readonly="!['supervisor'].includes(user.role.name)" label="Nome do Programa" v-model="form.name"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Local do Atendimento" :items="places" v-model="form.places"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Fase do Programa" :items="fases" v-model="form.fase"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Tipo de Ajuda" :items="helps" v-model="form.help"/>
                </v-col>
                <v-col cols="12">
                  <v-row dense align="center">
                    <v-col>
                      <v-text-field dense outlined hide-details type="number" :min="0" :max="100" label="Atraso (segundos)" v-model="form.delay"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="1" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <v-icon color="red">mdi-minus</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="2" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <v-icon color="green">mdi-plus</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="3" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <v-icon color="blue">mdi-plus-circle-outline</v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-row dense align="center" justify="center">
                        <v-col cols="auto">
                          <v-checkbox hide-details class="mt-0" value="4" v-model="form.status"/>
                        </v-col>
                        <v-col cols="auto">
                          <span class="orange--text">
                            <svg width="20" height="17" viewBox="0 0 20 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="mb-n1">
                              <path d="M0.5 9.5V11.5H8.5V9.5H0.5Z" fill="currentColor"/>
                              <path d="M10.581 10.3604C10.581 8.6582 10.9843 7.29102 11.7909 6.25879C12.5976 5.21973 13.6537 4.7002 14.9594 4.7002C16.2582 4.7002 17.287 4.8588 18.0458 5.74747V0.25H19.9428V16H18.1996L18.1073 15.1889C17.3486 16.1186 16.2924 16.2051 14.9389 16.2051C13.6537 16.2051 12.6044 15.6787 11.7909 14.626C10.9843 13.5732 10.581 12.1992 10.581 10.5039V10.3604ZM12.478 10.5757C12.478 11.8335 12.7377 12.8179 13.2572 13.5288C13.7768 14.2397 14.4946 14.5952 15.4106 14.5952C16.6137 14.5952 17.4921 13.7258 18.0458 12.6457V8.09987C17.4784 7.05397 16.6069 6.31006 15.4311 6.31006C14.5014 6.31006 13.7768 6.66895 13.2572 7.38672C12.7377 8.10449 12.478 9.16748 12.478 10.5757Z" fill="currentColor"/>
                            </svg>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense align="center">
                    <v-col>
                      <v-text-field dense outlined hide-details type="number" :min="1" :max="100" label="Tentativas" v-model="form.attempts"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="auto">
                      <v-checkbox hide-details label="Randomizar" class="mt-0" v-model="form.randomize" />
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox hide-details label="Sonda" class="mt-0" v-model="form.probe" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <template v-if="form.stimulus.length">
                    <v-card outlined class="mb-2">
                      <v-simple-table>
                        <tbody>
                          <template v-for="(stimulu, index) in form.stimulus">
                            <tr :key="index">
                              <td class="text-left">
                                {{ stimulu.name }}
                              </td>
                              <td class="text-right">
                                <v-btn small icon @click="onRemoveStimulu(index)">
                                  <v-icon size="20">mdi-trash-can-outline</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </v-simple-table>
                    </v-card>
                  </template>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field dense outlined hide-details label="Novo estímulo" v-model="stimulu.name" @keydown.enter.prevent="onAddStimulu">
                    <template #append>
                      <v-btn tile icon class="mt-n2 mr-n2" @click="onAddStimulu">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined hide-details label="Observações" v-model="form.comments"/>
                </v-col>
                <!-- <v-col cols="12">
                  <v-checkbox hide-details class="mt-0" label="Finalizado" v-model="form.finished"/>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tertiary">
            <v-btn text @click="onClose">Fechar</v-btn>
            <v-spacer />
            <v-btn depressed type="submit" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-loading>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ProgramService from '../services/program';
import ProgramInitial from '../models/ProgramInitial';

const stimuluInitial = () => ({name: '', type: '1'});

export default {
  props: ["helps", "fases", "value", "student", "program"],
  data: () => ({
    saving: false,
    loading: false,
    stimulu: stimuluInitial(),
    errors: {},
    places: [
      {value: '1', text: 'Escola'}, 
      {value: '2', text: 'Casa'},
      {value: '3', text: 'Externo'},
      {value: '4', text: 'Em clínica'},
    ],
    form: ProgramInitial({id: null}),
  }),
  watch: {
    value(value) {
      if (value) {
        this.mountData();
      }
    },
  },
  methods: {
    fetchProgram(id) {
      this.loading = true;

      ProgramService.find(id)
        .then((data) => {
          this.form = data;
        })
        .catch(() => {
          this.onClose();
        })
        .then(() => (this.loading = false));
    },
    mountData() {
      this.form = ProgramInitial({id: null});
      this.stimulu = stimuluInitial();
      this.fetchProgram(this.program.id);
    },
    onClose() {
      this.$emit("input", false);
    },
    onSubmit() {
      ProgramService.update({
        student: this.student.id,
        ...this.form,
      })
        .then((data) => {
          this.$emit("save", data);
          this.onClose();
        })
        .catch((data) => {
          this.$root.$emit("snackbar", {
            text: "Não foi possível salvar os dados.",
            color: "red",
          });
          this.errors = data.errors || {};
        })
        .then(() => {
          this.saving = false;
        });
    },
    onAddStimulu() {
      if (this.notHasStimulu) {
        this.form.stimulus.push(this.stimulu);
        this.stimulu = stimuluInitial();
      } else {
        alert('Estimulo inválido!');
      }
    },
    onRemoveStimulu(index) {
      this.form.stimulus.splice(index, 1);
    },
  },
  computed: {
    notHasStimulu() {
      return this.stimulu && this.stimulu.name && !this.form.stimulus.some(stimulu => {
        return stimulu.name == this.stimulu.name;
      });
    },
    user() {
      return this.me().user
    },
  },
};
</script>